import { GetServerSideProps } from 'next';
import Link from 'next/link';
import { parseCookies } from 'nookies';
import { FormEvent, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { ReCAPTCHA } from 'components/ReCAPTCHA';
import { useAuth } from 'contexts/AuthContext';
import { ReCAPTCHAProvider, useReCAPTCHA } from 'contexts/ReCAPTCHAContext';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { recaptcha, refreshRecaptcha, setRefreshRecaptcha, handleVerifyRecaptcha } = useReCAPTCHA();
  const { signIn, loading } = useAuth();
  const [typeInputPassword, setTypeInputPassword] = useState<'password' | 'text'>('password');

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    const data = {
      email,
      password,
      recaptcha,
    };

    await signIn(data);
    //setRefreshRecaptcha(Date.now());
  }

  return (
    <ReCAPTCHAProvider>
      <Wrapper>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Content>
          <Left>
            <img src="/login-left.png" alt="background" />
          </Left>
          <Right>
            <Form>
              <Container>
                <Header>
                  <img src="/Logo.svg" alt="logo" />
                </Header>
                <form onSubmit={handleSubmit}>
                  <Group>
                    <img src="/user-icon.png" alt="user" />
                    <input type="email" placeholder="E-mail" onChange={(e) => setEmail(e.target.value)} />
                  </Group>
                  <Group style={{ position: 'relative' }}>
                    <img src="/password-icon.png" alt="user" />
                    <input type={typeInputPassword} placeholder="Senha" onChange={(e) => setPassword(e.target.value)} />
                    {typeInputPassword === 'text' ? (
                      <AiOutlineEyeInvisible
                        style={{ position: 'absolute', right: '15px', top: '20px', cursor: 'pointer' }}
                        size={25}
                        onClick={() => setTypeInputPassword('password')}
                      />
                    ) : (
                      <AiOutlineEye
                        size={25}
                        style={{ position: 'absolute', right: '15px', top: '20px', cursor: 'pointer' }}
                        onClick={() => setTypeInputPassword('text')}
                      />
                    )}

                    <Link href="/reset-password">Esqueceu sua senha ? Clique aqui</Link>
                  </Group>

                  <ReCAPTCHA onVerify={handleVerifyRecaptcha} refreshReCaptcha={refreshRecaptcha} />
                  <SessionContainer>
                    <Link href="/registration">CRIAR CONTA</Link>
                    <Button type="submit">{loading ? 'Carregando' : 'Entrar'}</Button>
                  </SessionContainer>
                </form>
              </Container>
            </Form>
          </Right>
        </Content>
      </Wrapper>
    </ReCAPTCHAProvider>
  );
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const cookies = parseCookies(ctx);

  if (cookies['meunascimento.token']) {
    return {
      redirect: {
        destination: '/profile/user',
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};

const Wrapper = styled.div``;

const SessionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 2rem;

  & * {
    font-weight: 600 !important;
    font-size: 16px !important;
  }

  & a {
    &,
    :link,
    :visited {
      border-radius: 10px;
      width: 100%;
      border: none;
      font-weight: 500;
      margin-top: 0.5rem;
      padding: 1.5rem 0;
      background: transparent;
      font-weight: 600;
      color: #174f68;
      border: solid 2px #174f68;
      text-decoration: none;
      cursor: pointer;
      text-transform: uppercase;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      text-align: center;
    }
  }
`;

const Content = styled.div`
  width: auto;
  height: 100vh;
  display: flex;
  background: #fbf9ed;

  justify-content: space-between;

  @media (max-width: 766px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  position: relative;
  height: 100vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 2px 25px 25px 2px;

  img {
    max-width: 101%;
    object-fit: cover;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

const Right = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  margin-right: 7rem;

  @media (max-width: 766px) {
    height: 100vh;
    width: 100vw;
  }
`;

const Form = styled.div`
  max-width: 411px;

  @media (max-width: 390px) {
    width: 74vw;
  }

  @media (max-width: 766px) {
    width: 84vw;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;

  form {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

const Header = styled.div`
  padding: 3rem;
  img {
    margin-bottom: 1rem;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  fill: red;

  input {
    width: 100%;
    font-size: 1.5rem;
    padding: 2rem 4.8rem;
    border: solid 2px #174f68;
    border-radius: 10px;
    background: #fbf9ed;
  }

  input::placeholder {
    font-size: 15px;
    color: #222222;
    font-weight: 500;
  }

  input:hover {
    border: solid 2px #797bb0;
    color: #0076a0;
  }

  input:focus {
    font-size: 14px;
    border: solid 2px #174f68;
    color: #222222;
  }

  input:focus,
  select:focus {
    box-shadow: 0.3px 0.3px 0.3px;
    outline: 0;
  }

  img {
    position: absolute;
    margin: 10px 8px;
  }

  > a {
    font-size: 16px;
    color: #f09192;
    transition: all 0.5s;
    margin-top: 8px;
    text-decoration: none;
    text-align: end;
  }

  @media (max-width: 766px) {
    > a {
      font-size: 13px;
    }
  }
`;

const Button = styled.button`
  border-radius: 10px;
  width: 100%;
  border: none;
  font-weight: 500;
  margin-top: 0.5rem;
  padding: 1.5rem 0;
  background: #174f68;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.5s;

  :hover {
    opacity: 0.6;
  }
`;

const Alternative = styled.div`
  margin-top: 3rem;
  margin-bottom: 1.6rem;
  text-align: center;

  span {
    font-size: 1.5rem;
    font-weight: 500;
    color: #174f68;
    position: relative;
  }

  span:before,
  span:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 140px;
    bottom: 50%;
    right: 100px;
    background: #174f68;
  }

  span:after {
    left: 100px;
  }

  @media (max-width: 766px) {
    span:before,
    span:after {
      width: 90px;
      bottom: 50%;
      right: 100px;
    }
  }
`;

const Cadastrese = styled.div`
  text-align: center;
  margin-top: 2rem;

  > a {
    font-size: 20px;
    transition: all 0.5s;
    color: #174f68;
    font-weight: 100;
    text-decoration: none;
    cursor: pointer;
  }

  :hover {
    opacity: 0.5;
  }

  @media (max-width: 766px) {
    font-size: 13px;
  }
`;
